<template>
  <div class="contenBox">
    <h1 style="margin-bottom:10px;font-size: 20px;color: #0c263f;">危险品禁运查询</h1>
    <div class="search">
      <div class="searchFl">
        <unnoSelect v-model="show_un_no" @unnoSelectFunc="unnoSelectFunc($event)" :unnoType="1" :clearable="true"
          class="searchInput" :width="'390px'" :placeTitle="'请输入UNNO，必填'" :size="'medium'"></unnoSelect>
        <shipCompany
          :placeTitle="'请输入船公司简称，如KMTC，选填'"
          :width="'390px'"
          :size="'medium'"
          class="searchInput"
          v-model="searchQuery.shipping_company_id"
        ></shipCompany>
        <el-button type="primary" @click="searchChange" size="medium" icon="el-icon-search" style="background-color: #91be42;border-color: #91be42;">搜索</el-button>
      </div>
    </div>
    <div class="unnoBox" >
      <template v-if="searchQuery.un_no">
        <div>Class：{{unnoSelectObj.classify}}</div>
        <div>中文品名：{{unnoSelectObj.pscn}}</div>
        <div class="tips" @click="gotoUnno">更多危险品信息<i class="el-icon-d-arrow-right"></i></div>
      </template>
    </div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        stripe
        :empty-text="emptyText"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column label="船公司简称"  >
          <template slot-scope="scope">
            {{scope.row.shot_name | textFormat }}
          </template>
        </el-table-column>
        <el-table-column label="IMDG CLASS"  >
          <template slot-scope="scope">{{scope.row.imdg_class | textFormat }}</template>
        </el-table-column>
        <el-table-column label="限制类型"  >
          <template slot-scope="scope">{{scope.row.limited_type | limited_typeFormat }}</template>
        </el-table-column>
        <el-table-column label="备注"  width="600">
          <template slot-scope="scope">{{scope.row.remarks | textFormat }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="15"
        layout="total, prev, pager, next"
        :total="total"
        :current-page.sync="currentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import unnoSelect from "~ymp/components/common/unno"
import shipCompany from "~ymp/components/common/shipCompany"
export default {
  name: "dangerousEmbargo",
  components:{
    unnoSelect,
    shipCompany
  },
  data() {
    return {
      loading: false,
      dialogShow: false,
      dialogShowUser: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      show_un_no:'',
      tableData: [],
      searchQuery:{
        un_no:"",
        shipping_company_id:'', 
      },
      unnoSelectObj:{},
      emptyText: '请先输入UNNO'
    };
  },
  mounted() {
  },
  methods: {
    unnoSelectFunc(e) {
      if(e){
        this.unnoSelectObj = e;
        this.searchQuery.un_no = e.un_no;
        this.emptyText = ' '
      }else{
        this.searchQuery.un_no = '';
        this.emptyText = '请先输入UNNO'
      }
		},
    gotoUnno(){
      this.$parent.tabClick(2);
      this.$nextTick(()=>{
        this.$parent.$refs.unnoSearchRef.searchQuery = this.unnoSelectObj.un_no;
        this.$parent.$refs.unnoSearchRef.handleSearch();
      })
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        limit: 15,
        start: this.currentPage - 1,
        nopage:0,
        imdg_class: this.unnoSelectObj.classify
      };
      params = Object.assign(params, this.searchQuery)
      let result = await this.$store.dispatch( //
        "API_freight/dangerousEmbargoList",
        params
      );
      this.loading = false;
      if (result && result.success) {
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
        if(this.total == 0){
          this.emptyText = '该危险品不在船公司禁运范围内'
        }
      }
    },
    searchChange() {
      if(!this.searchQuery.un_no){
        this.$message.warning(`请先输入UNNO`);
        return;
      }
      this.$log({
        type: 1,
        topic: "ymp.tool",
        id: "ymp.tool.embargo.search",
        content: JSON.stringify({
          unno: this.searchQuery.un_no || '',
          company:  this.searchQuery.shipping_company_id || ''
        }),
      });
      this.tableData = [];
      this.currentPage = 1;
      this.getList();
    },
  },
  filters:{
    limitedFormat(val){
      let str = "-";
      if (val == 1) {
        str = "ALL PROHIBITED";
      }
      if (val == 2) {
        str = "Part UnNo";
      }
      return str;
    },
    limited_typeFormat(val){
      let str = "-";
      if (val == 1) {
        str = "禁止";
      }
      if (val == 2) {
        str = "受限";
      }
      return str;
    },
  }
};
</script>
<style scoped lang="less">
.contenBox{
  width: 1300px;
  margin: auto;
  padding-top:20px;
}
.search {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 400px;
      padding: 0;
    }
  }
}
.unnoBox{
  display:flex;
  padding: 20px 10px;
  div{
    margin-right: 100px;
  }
}
.has-gutter{
  background: #EEEEEE;
}

.tips{
  color: #027fff;
  margin-left: 10px;
  cursor: pointer;
}
.paginationBlock{
  text-align: right;
  padding: 15px 0;
}
</style>