<template>
  <div>
    <el-select v-model="show_un_no" filterable :size="size" :placeholder="placeTitle" remote :remote-method="remoteMethod" v-show="unnoType"
      :clearable="clearable" :disabled="disabled" :loading="loading" :style="{ width: width }"
      :default-first-option='true'>
      <el-option v-for="item in options" :key="item.id" :label="`${item.un_no}/${item.classify}/${item.packing_group}`" :value="item.new_val">
        <div>{{ `${item.un_no}/${item.classify}/${item.packing_group}`}}</div>
      </el-option>
    </el-select>
    <el-select v-model="un_no" filterable :size="size" :placeholder="placeTitle" remote :remote-method="remoteMethod" v-show="!unnoType"
      :clearable="clearable" :disabled="disabled" :loading="loading" :style="{ width: width }"
      :default-first-option='true'>
      <el-option v-for="item in options" :key="item.id" :label="item.un_no" :value="item.un_no">
        <div>{{ item.un_no }}</div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "unnoSelect",
  props: {
    width: {
      type: String,
      default: "",
    },
    placeTitle: {
      type: String,
      default: "",
    },
    value: {
      type: String | Number,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unnoType:{
      type: String | Number,
      default: null,
    },
    show_un_no_val:{
      type: String | Number,
      default: null,
    }
  },
  data() {
    return {
      options: [],
      un_no: null,
      show_un_no: null,
      loading: false,
    };
  },
  mounted() {
    if(!this.unnoType){
      this.handleInitValue();
    }
    if(this.show_un_no_val){
      this.show_un_no = this.show_un_no_val;
    }
  },
  watch: {
    un_no(nv) {
      let obj = this.options.find(item => {
        return item.un_no == nv
      });
      this.$emit("unnoSelectFunc", obj);
    },
    show_un_no(nv) {
      let obj = this.options.find(item => {
        return item.new_val == nv;
      });
      this.$emit("unnoSelectFunc", obj);
    },
    value(nv) {
      if (nv) {
        this.handleInitValue()
      }
    }
  },
  methods: {
    async handleInitValue() {
      if (this.value != "") {
        let parmas = {};
        parmas.un_no = this.value;

        this.$store.dispatch("API_bookingSpace/getUnnoList", parmas).then((res) => {
          this.loading = false;
          if (res.success) {
            this.options = res.data;
            this.un_no = this.value
          }
        });
      } else {
        // this.un_no=this.value
      }
    },
    async remoteMethod(queryString) {
      this.loading = true;
      let parmas = {};
      parmas.un_no = queryString;
      parmas.limit = 20;
      parmas.start = 0;
      this.$store.dispatch("API_bookingSpace/getUnnoList", parmas).then((res) => {
        this.loading = false;
        if (res.success) {
          if(res.data){
            res.data.forEach(ele => {
              ele.new_val = `${ele.un_no}_${ele.classify}_${ele.packing_group}`
            });
          }
          this.options = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
