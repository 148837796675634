<template>
  <div class="contenBox">
    <div class="search">
      <div class="searchBox">
        <portSelect
          v-model="searchQuery.pod_id"
          placeTitle="请输入港口中英文名称"
          :size="'medium'"
          :width="'350px'"
          clearable
        />
        <el-button type="primary" @click="searchChange" size="medium" icon="el-icon-search"
          style="background-color: #91be42;border-color: #91be42; margin-left: 10px;">搜索</el-button>
      </div>
    </div>
    <div class="tableBlock">
      <el-table :data="tableData" style="width: 100%" :height="tableHeight" stripe :empty-text="emptyText"
        v-tableHeight="{ bottomOffset: 74 }" :tableLoading="loading">
        <el-table-column label="预计开航日期" >
          <template slot-scope="scope">{{ scope.row.etd_time | secondFormat('LL') }}</template>
        </el-table-column>
        <el-table-column label="目的港" >
          <template slot-scope="scope">{{ scope.row.pod_name_en | textFormat }} {{ scope.row.pod_name_cn  ? '('+ scope.row.pod_name_cn + ') ':'' }}</template>
        </el-table-column>
        <el-table-column label="属性" >
          <template slot-scope="scope">{{ scope.row.dangerous_remarks | textFormat }}</template>
        </el-table-column>
        <el-table-column label="船公司"  width="100">
          <template slot-scope="scope">{{ scope.row.shipping_company_shot_name | textFormat }}</template>
        </el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">{{ scope.row.operator_name | textFormat }}</template>
        </el-table-column>
        <el-table-column label="箱型" >
          <template slot-scope="scope">{{ scope.row.container_info | textFormat }}</template>
        </el-table-column>
        <el-table-column label="已拼" >
          <template slot-scope="scope">
            {{ scope.row.total_vice | textFormat }}{{ scope.row.total_vice ? '票':'' }} 
            {{ scope.row.entrust_volume  ? ' | ' + scope.row.entrust_volume+'CBM':'' }}
          </template>
        </el-table-column>
        <el-table-column label="订舱" >
          <template slot-scope="{ row }">
            <span style="color: #91be42;" v-if="row.is_booking == 1">已订舱</span> 
            <span v-if="row.is_booking == 0">未订舱</span> 
          </template>
        </el-table-column>
        <el-table-column label="是否满载" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.is_full == 1">
              <el-button type="danger" size="small">已满</el-button>
            </template>
            <template v-else>否</template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination @current-change="handleChange" :page-size="15" layout="total, prev, pager, next" :total="total" :current-page.sync="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import portSelect from "~ymp/components/common/portSelect";
export default {
  name: "dangerousAirRoute",
  components: {
    portSelect
  },
  data() {
    return {
      loading: false,
      dialogShow: false,
      dialogShowUser: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      show_un_no:'',
      tableData: [],
      searchQuery: {
        pod_id: ''
      },
      emptyText: '请先输入查询条件'
    };
  },
  mounted() {
  },
  methods: {
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        limit: 15,
        start: this.currentPage - 1,
        etd_start: this.$moment().unix(),
        nopage: 0,
        is_hidden: 0
      };
      params = Object.assign(params, this.searchQuery)
      let result = await this.$store.dispatch( //
        "API_freight/dangerousAirRouteList",
        params
      );
      this.loading = false;
      if (result && result.success) {
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
        if(this.total == 0){
          this.emptyText = '该港口暂未发现排期数据'
        }
      }
    },
    searchChange() {
      this.tableData = [];
      this.currentPage = 1;
      this.getList();
    },
  },
  filters: {
    limitedFormat(val) {
      let str = "-";
      if (val == 1) {
        str = "全面禁运";
      }
      if (val == 2) {
        str = "禁止散装";
      }
      return str;
    },
  }
};
</script>
<style scoped lang="less">
.contenBox {
  width: 1300px;
  margin: auto;
  padding-top: 20px;
}

.search {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .searchBox {
    width: 400px;
    display: flex;
    align-items: center;
    .el-input{
      margin-right: 10px;
    }
  }
}

.unnoBox {
  display: flex;
  padding: 20px 10px;

  div {
    margin-right: 100px;
  }
}

.has-gutter {
  background: #EEEEEE;
}

.tips {
  color: #027fff;
  margin-left: 10px;
  cursor: pointer;
}

.paginationBlock {
  text-align: right;
  padding: 15px 0;
}
</style>