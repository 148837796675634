<template>
  <div class="t_box">
    <div class="p_header">
      <div class="my_tabs">
        <div :class="{ tab_active: activeName == '1' }" @click="tabClick('1')">货物到港查询</div>
        <div :class="{ tab_active: activeName == '2' }" @click="tabClick('2')">UNNO查询</div>
        <el-dropdown @command="handleCommand">
          <div :class="{ tab_active: activeName == '3' }">危险品禁运查询<i class="el-icon-arrow-down el-icon--right"
              style="position: absolute; top: 37%; right: -15px;"></i></div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">船司禁运危化品</el-dropdown-item>
            <el-dropdown-item command="2">危化品名录</el-dropdown-item>
            <el-dropdown-item command="3">内河禁运</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div :class="{ tab_active: activeName == '4' }" @click="tabClick('4')" v-if="$store.state.baseStore.userInfo.user_type == 2">危拼航线排期</div>
      </div>
    </div>

    <CargoArrivalInquiry v-if="activeName == '1'"></CargoArrivalInquiry>
    <UnnoSearch ref="unnoSearchRef" v-show="activeName == '2'" />
    <dangerousEmbargo v-show="activeName == '3' && subType == 1" />
    <hazardousDirectory v-show="activeName == '3' && subType == 2" />
    <inlandRiverEmbargo v-show="activeName == '3' && subType == 3" />
    <dangerousAirRoute ref="dangerousAirRouteRef" v-show="activeName == '4'" />
  </div>
</template>
<script>
import CargoArrivalInquiry from '~ymp/components/tools/CargoArrivalInquiry'
import UnnoSearch from '~ymp/components/tools/UnnoSearch'
import Port from '~ymp/components/tools/Port'
import dangerousEmbargo from '~ymp/components/tools/dangerousEmbargo'
import hazardousDirectory from '~ymp/components/tools/hazardousDirectory'
import inlandRiverEmbargo from '~ymp/components/tools/inlandRiverEmbargo'
import dangerousAirRoute from '~ymp/components/tools/dangerousAirRoute'


export default {
  components: {
    CargoArrivalInquiry,
    UnnoSearch,
    Port,
    dangerousEmbargo,
    hazardousDirectory,
    inlandRiverEmbargo,
    dangerousAirRoute
  },
  data() {
    return {
      activeName: '2',
      subType: 0
    }
  },
  mounted(){
    this.$log({
      type: 2,
      topic: "ymp.tool",
      id: "ymp.tool"
    });
    if(this.$route.query.toolsType){
      this.activeName = this.$route.query.toolsType || 2;
      this.$refs.dangerousAirRouteRef.getList(); 
    }
  },
  methods: {
    tabClick(e) {
      if(e == 1 || e == 2){
        this.$log({
          type: 2,
          topic: "ymp.tool",
          id: e == 1? 'ymp.tool.arrival': e == 2 ? 'ymp.tool.unno':''
        });
      }
      this.activeName = e
    },
    handleCommand(e) {
      this.activeName = 3;
      this.subType = e;
      if(e == 1 || e == 2){
        this.$log({
          type: 2,
          topic: "ymp.tool",
          id: e == 1? 'ymp.tool.embargo': e == 2 ? 'ymp.tool.haz':''
        });
      }
    }
  }
}
</script>
<style lang="less" scoped>
.p_header {
  background: #EEEEEE;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.my_tabs {
  width: 550px;
  display: flex;
  justify-content: space-between;

  >div {
    width: 110px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }

  .tab_active {
    color: rgb(145, 190, 66);
    border-bottom: 2px solid rgb(145, 190, 66);
  }
}
/deep/ .el-dropdown-menu__item:hover{
  color:rgb(145, 190, 66);
}
</style>