<template>
  <div>
    <div class="contenBox">
      <h1 style="margin-bottom:10px;font-size: 20px;color: #0c263f;">货物到港查询</h1>
      <div class="searchBox">
        <el-input :placeholder="search_type == 1 ? '请输入YM No' : '请输入HBL No'" v-model="searchQuery" clearable
          class="input-with-select" @keyup.enter.native="handleSearch">
          <el-select v-model="search_type" slot="prepend" placeholder="请选择">
            <el-option label="YM No" value="1"></el-option>
            <el-option label="HBL No" value="2"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
        </el-input>
      </div>
      <div v-if="left_is_search" v-loading="loading">
        <div class="card" v-if="JSON.stringify(arrival_tracking) != '{}'">
          <div class="head">
            <div> B/L No：{{ pageDetail.m_bl | textFormat }}</div>
            <div> Vessel/Voyage： {{ pageDetail.vessel_voyage | textFormat }}</div>
            <div> Container No： {{ pageDetail.container_no | textFormat }} </div>
            <div> ETA： {{ pageDetail.eta | secondFormat("LL") }}</div>
          </div>
          <div class="cont">
            <div class="item">
              <div class="left">
                <div>HB/L : {{ arrival_tracking.hbl_no }}</div>
                <div>P’kgs: {{ arrival_tracking.quantity }} {{ arrival_tracking.packing_name_en | textFormat }}</div>
                <div>Gross Weight: {{ arrival_tracking.gross_weight ? arrival_tracking.gross_weight + 'KGS' : '-' }}
                </div>
                <div>Measurenent: {{ arrival_tracking.volume ? arrival_tracking.volume + 'CBM' : '-' }} </div>
                <div class="triangle"><i class="el-icon-caret-right"></i></div>
              </div>
              <div class="right">
                <div class="right-item" v-for="(li, index) in arrival_tracking.arrival_tracking_node" :key="li.id">
                  <div>
                    <img :src="li.img" alt="">
                  </div>
                  <div class="green">{{ li.en }}</div>
                  <div class="status">{{ li.default_status == 1 ? 'Pending' : li.o_state }}
                    <!-- 视为到货通知 -->
                    <i v-if="li.default_status != 1 && li.node == 1" class="el-icon-s-order green cursor"
                      @click="openDialog(li)"></i>
                  </div>
                  <div class="value" v-if="li.default_status != 1 && li.node == 6">
                    ETD: {{ li.content.delivery_etd | secondFormat("LL") }} /
                    ETA: {{ li.content.delivery_eta | secondFormat("LL") }}
                  </div>
                  <div class="value" v-else-if="li.default_status != 1 && li.node == 3">
                    Vessel: {{ li.content.vessel | textFormat }} <br>
                    Voyage: {{ li.content.voyage | textFormat }} <i class="el-icon-date green cursor"
                      @click="openDialog(li)"></i>
                  </div>
                  <div class="value" v-else-if="li.default_status != 1">
                    {{ li.content.setVal | secondFormat("LL") }}
                  </div>
                  <div class="value" v-else></div>
                  <img class="arrow" v-if="index != arrival_tracking.arrival_tracking_node.length - 1"
                    src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/enter-arrow-right.png"
                    alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nodata" v-else>
          暂无查询结果
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="showDialog" width="550px" :close-on-click-modal="false">
      <div class="documentList" v-if="ruleForm.module_type == 1">
        <div class="document" v-for="(item, index) in filelist" :key="index">
          <img v-if="item.type == 'XLS'"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/file/XLS.png" alt="">
          <img v-else-if="item.type == 'TXT' || item.type == 'DOC' || item.type == 'PDF' || item.type == 'ZIP'"
            :src="`https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/file/${item.type.toLowerCase()}.png`"
            alt="">
          <template v-else>
            <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/file/txt.png" alt="">
            <span class="file_type">{{ item.type }}</span>
          </template>
          <p @click="downloadFile(item.url)">{{ item.name }}</p>
        </div>
        <div v-if="filelist.length == 0" style="text-align: center; width: 100%; padding: 30px;">暂无数据</div>
      </div>
      <div class="myItem" v-if="ruleForm.module_type == 3">
        <p>ETD: {{ ruleForm.transport_etd | secondFormat("LLLL") }}</p>
        <p>ETA: {{ ruleForm.transport_eta | secondFormat("LLLL") }}</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search_type: "1",
      searchQuery: "",
      left_is_search: false,
      pageDetail: {},
      arrival_tracking: {},
      filelist: [],
      loading: false,
      showDialog: false,
      ruleForm: {}
    }
  },
  methods: {
    openDialog(data) {
      let scope = Object.assign({}, data);
      this.filelist = scope.content.filelist || [];
      this.ruleForm = {
        module_type: scope.node, //模块类型
        transport_etd: scope.content.transport_etd ? scope.content.transport_etd * 1 : "",
        transport_eta: scope.content.transport_eta ? scope.content.transport_eta * 1 : "",
      }
      this.showDialog = true;
    },
    filterNode(e) {
      switch (e.node) {
        case 1:
          e.img = 'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/Arrival-tracking.png';
          e.en = 'Notice of arrival';
          e.cn = '到货通知';
          e.o_state = 'Notified';
          break;
        case 2:
          e.img = 'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/Unpacking.png';
          e.en = 'Unpacking';
          e.cn = '拆箱';
          e.o_state = 'Unpacked';
          break;
        case 3:
          e.img = 'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/transport.png';
          e.en = 'Transport';
          e.cn = '二程转运';
          e.o_state = 'Transferred';
          break;
        case 4:
          e.img = 'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/change-order.png';
          e.en = 'Change Order';
          e.cn = '换单';
          e.o_state = 'Replaced';
          break;
        case 5:
          e.img = 'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/Self-lifting.png';
          e.en = 'Self Lifting';
          e.cn = '客户自提';
          e.o_state = 'Proposed';
          break;
        case 6:
          e.img = 'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/Dispatch.png';
          e.en = 'Dispatch';
          e.cn = '派送';
          e.o_state = 'Delivery';
          break;
      }
    },
    async handleSearch() {
      this.pageDetail = {};
      this.arrival_tracking = {};
      if (!this.searchQuery) {
        return
      }
      this.$log({
        type: 1,
        topic: "ymp.tool",
        id: "ymp.tool.arrival.search",
        content: JSON.stringify({
          type: this.search_type == 1 ? 'ym' : '',
          no: this.searchQuery || ''
        }),
      });
      this.left_is_search = true
      this.loading = true
      let params = {}
      if (this.search_type == 1) {
        params.ym_no = this.searchQuery
      } else {
        params.h_bl = this.searchQuery
      }
      let res = await this.$store.dispatch("API_freight/arrival_tracking_list", params)
      if (res.success) {
        this.pageDetail = res.data;
        if (res.data && res.data.arrival_tracking) {
          let item = res.data.arrival_tracking;
          if (item.arrival_tracking_node && item.arrival_tracking_node.length) {
            item.arrival_tracking_node.forEach((e) => {
              try {
                if (e.content) {
                  e.content = JSON.parse(e.content)
                } else {
                  e.content = {};
                }
              } catch (error) {
                console.error('节点内容解析失败', error)
                e.content = {};
              }
              this.filterNode(e);
            })
            item.arrival_tracking_node = item.arrival_tracking_node.filter((item) => {
              return item.is_show == 1
            })
          }
          this.arrival_tracking = res.data.arrival_tracking;
        }
        this.total = res.count;
      }
      this.loading = false
    }
  }
}
</script>
<style lang="less" scoped>
.contenBox {
  width: 1300px;
  margin: auto;
  padding-top: 20px;
}

.searchBox {
  width: 500px;
  padding-bottom: 20px;
}

.card {
  .head {
    display: flex;
    height: 58px;
    background: #909399;
    padding: 11px 20px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 36px;

    div {
      margin-right: 50px;
    }
  }

  .cont {
    .item {
      display: flex;
      border-bottom: 1px solid #eee;

      .left {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 260px;
        padding-left: 20px;
        background-color: #F9F9F9;
        box-shadow: 0px 0px 8px 0px rgba(114, 114, 114, 0.15);

        div {
          padding: 10px 0;
          word-break: break-all;
        }

        .triangle {
          position: absolute;
          top: calc(50% - 36px);
          right: -25px;

          i {
            color: #f9f9f9;
            font-size: 36px;
          }
        }
      }

      .right {
        display: flex;
        width: 100%;

        .right-item {
          position: relative;
          width: 20%;
          padding: 10px;

          img {
            margin-top: 20px;
            width: 40px;
          }

          display: flex;
          flex-direction: column;
          align-items: center;

          .green {
            color: #91BE44;
            font-size: 16px;
            padding: 10px 0;
          }

          .status {
            padding-bottom: 10px;

            i {
              padding: 0;
            }
          }

          .value {
            padding: 0 20px;
            padding-bottom: 10px;
            color: #B7B7B7;
            text-align: center;

            i {
              padding: 0;
            }
          }

          .btn {
            padding: 10px 20px;
            border: 1px solid #91BE44;
            border-radius: 5px;
            color: #91BE44;
            cursor: pointer;
          }

          .arrow {
            position: absolute;
            right: -30px;
            top: 40%;
            padding: 0;
            width: 30%;
          }
        }
      }
    }
  }
}

.documentList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  p {
    padding: 10px 0;
  }

  .document {
    width: 33%;
    position: relative;

    .file_type {
      position: absolute;
      top: 32px;
      left: 3px;
      display: inline-block;
      width: 44px;
      height: 20px;
      text-align: center;
      background-color: #f9ca06;
      border-radius: 4px;
      color: white;
    }

    p {
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: #91BE44;
      }
    }

    img {
      width: 50px;
    }
  }
}
.myItem{
  p{
    padding-bottom: 10px;
  }
}

.nodata {
  text-align: center;
  line-height: 500px;
  color: #909399;
}
</style>