import { render, staticRenderFns } from "./dangerousEmbargo.vue?vue&type=template&id=24c5a76e&scoped=true"
import script from "./dangerousEmbargo.vue?vue&type=script&lang=js"
export * from "./dangerousEmbargo.vue?vue&type=script&lang=js"
import style0 from "./dangerousEmbargo.vue?vue&type=style&index=0&id=24c5a76e&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c5a76e",
  null
  
)

export default component.exports