<template>
  <div>
      <div class="contenBox">
          <div class="searchBox">
            <el-input placeholder="请输入unno" v-model="searchQuery" clearable  class="input-with-select" @keyup.enter.native="handleSearch">
              <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
            </el-input>
          </div>
          <el-card shadow="never" class="myCard">
              <el-row :gutter="20">
                <el-col :span="3">预计开航日期</el-col>
                <el-col :span="4">目的港</el-col>
                <el-col :span="2">属性</el-col>
                <el-col :span="4">船公司</el-col>
                <el-col :span="2">操作</el-col>
                <el-col :span="2">箱型</el-col>
                <el-col :span="2">已拼</el-col>
                <el-col :span="2">订舱</el-col>
                <el-col :span="3">是否满载</el-col>
              </el-row>
          </el-card>

          <el-card shadow="hover" class="myCard">
              <el-row :gutter="20">
                <el-col :span="3">预计开航日期预计开航日期预计开航日期</el-col>
                <el-col :span="4">目的港</el-col>
                <el-col :span="2">属性</el-col>
                <el-col :span="4">船公司</el-col>
                <el-col :span="2">操作</el-col>
                <el-col :span="2">箱型</el-col>
                <el-col :span="2">已拼</el-col>
                <el-col :span="2">订舱</el-col>
                <el-col :span="3">是否满载</el-col>
              </el-row>
          </el-card>
          
      </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      searchQuery:"",
      loading:false
    }
  },
  methods:{
    handleSearch(){

    }
  }
}
</script>
<style lang="less" scoped>
.contenBox{
  width: 1300px;
  margin: auto;
  padding-top:20px;
}
.searchBox{
  width:400px;
  padding-bottom:20px;
}
.noData{
  height: 500px;
  line-height: 500px;
  text-align: center;
}
.myCard{
  margin-bottom: 10px;
  /deep/ .el-col{
    text-align: center;
    overflow     : hidden;
    white-space  : nowrap !important;
    text-overflow: ellipsis;
  }
}

</style>