<template>
  <div>
    <div class="contenBox">
          <div class="searchBox">
            <el-input placeholder="请输入unno" v-model="searchQuery" clearable  class="input-with-select" @keyup.enter.native="handleSearch">
              <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
            </el-input>
          </div>
          <div  v-loading='loading' style="min-height:500px">
            <el-tabs v-model="activeName" @tab-click="handleClick" v-if="unnoArr.length>1">
              <el-tab-pane v-for="(item,i) in tabPaneOption" :key='i' :label="item.unno + '\n\n[\n\n'+ item.label +'\n\n]\n\n'" :name="String(i)"></el-tab-pane>
            </el-tabs>

            <div v-if="!loading && !unnoArr.length && searched" class="noData">
                暂无查询结果
            </div>

          <div class="unnoitem" v-for="(item,i) in showArr" :key='i'>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">UN NO.</div></el-col>
              <el-col :span="6"><div class="">{{item.un_no | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">PSN</div></el-col>
              <el-col :span="21"><div class="textOverflow" :title="item.psn">{{item.psn | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">中文品名</div></el-col>
              <el-col :span="21"><div class="textOverflow" :title="item.pscn">{{item.pscn | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">Class</div></el-col>
              <el-col :span="3"><div class="textOverflow" :title="item.classify">{{item.classify | textFormat}}</div></el-col>
              <el-col :span="3" :offset="6"><div class="">副危险</div></el-col>
              <el-col :span="6"><div class="textOverflow" :title="item.subsidiary_risks">{{item.subsidiary_risks | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">包装类</div></el-col>
              <el-col :span="3"><div class="textOverflow" :title="item.packing_group">{{item.packing_group | textFormat}}</div></el-col>
              <el-col :span="3" :offset="6"><div class="">特殊规定</div></el-col>
              <el-col :span="6"><div class="textOverflow" :title="item.special_provisions">{{item.special_provisions | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">限量</div></el-col>
              <el-col :span="3"><div class="textOverflow" :title="item.limited_quantities">{{item.limited_quantities | textFormat}}</div></el-col>
              <el-col :span="3" :offset="6"><div class="">可免除量</div></el-col>
              <el-col :span="6"><div class="textOverflow" :title="item.excepted_quantities">{{item.excepted_quantities | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">包装说明</div></el-col>
              <el-col :span="3"><div class="textOverflow" :title="item.packing_instructions">{{item.packing_instructions | textFormat}}</div></el-col>
              <el-col :span="3" :offset="6"><div class="">包装规定</div></el-col>
              <el-col :span="6"><div class="textOverflow" :title="item.packing_rovisions">{{item.packing_rovisions | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">IBCs导则</div></el-col>
              <el-col :span="3"><div class="textOverflow" :title="item.ibcs_instructions">{{item.ibcs_instructions | textFormat}}</div></el-col>
              <el-col :span="3" :offset="6"><div class="">IBCs规定</div></el-col>
              <el-col :span="6"><div class="textOverflow" :title="item.ibcs_provisions">{{item.ibcs_provisions | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">储罐说明</div></el-col>
              <el-col :span="3"><div class="textOverflow" :title="item.tanks_instructions">{{item.tanks_instructions | textFormat}}</div></el-col>
              <el-col :span="3" :offset="6"><div class="">储罐规定</div></el-col>
              <el-col :span="6"><div class="textOverflow" :title="item.tanks_provisions">{{item.tanks_provisions | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">应急计划</div></el-col>
              <el-col :span="3"><div class="textOverflow" :title="item.emergency_schedule">{{item.emergency_schedule | textFormat}}</div></el-col>
              <el-col :span="3" :offset="6"><div class="">积载与操作</div></el-col>
              <el-col :span="6"><div class="textOverflow" :title="item.stowage_and_handling">{{item.stowage_and_handling | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">隔离项</div></el-col>
              <el-col :span="6"><div class="textOverflow" :title="item.segregation">{{item.segregation | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">特性与注意事项(英文)</div></el-col>
              <el-col :span="21"><div class="textOverflow" :title="item.properties_and_observations">{{item.properties_and_observations  | textFormat}}</div></el-col>
            </el-row>
            <el-row :gutter="10" class="row_item">
              <el-col :span="3"><div class="">特性与注意事项(中文)</div></el-col>
              <el-col :span="21"><div class="textOverflow" :title="item.properties_and_observations_zh">{{item.properties_and_observations_zh | textFormat}}</div></el-col>
            </el-row>
          </div>
          </div>
          
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      searchQuery:"",
      isSearch:false,
      loading:false,
      unnoArr:[],
      showArr:[],
      base_option:[
        {index:"0",label:'I'},
        {index:"1",label:'II'},
        {index:"2",label:'III'},
        {index:"3",label:'IV'},
        {index:"4",label:'V'},
        {index:"5",label:'VI'},
        {index:"6",label:'VII'},
        {index:"7",label:'VIII'},
        {index:"8",label:'IX'},
        {index:"9",label:'X'},
      ],
      tabPaneOption:[],
      activeName:0,
      searched:false,
    }
  },
  methods:{
    handleClick(e){
      this.showArr=this.unnoArr.slice(e.index*1,e.index*1+1)
    },
    async handleSearch(){
      this.$log({
        type: 1,
        topic: "ymp.tool",
        id: "ymp.tool.unno.search",
        content: JSON.stringify({
          unno: this.searchQuery || ''
        }),
      });
      this.loading = true;
      let data = await this.$store.dispatch("API_bookingSpace/getUnno", {
        un_no:this.searchQuery,
      });
      this.unnoArr = data.data;
      this.searched=true
      if(data.data.length){
        this.showArr=data.data.slice(0,1)
        this.tabPaneOption = data.data.map((item,index)=>{
          return {index:index,unno:item.un_no, label:item.packing_group}
        })
      }else{
        this.showArr=[]
        this.tabPaneOption=[]
      }
      this.loading = false
    }
  }
}
</script>
<style lang="less" scoped>
  .contenBox{
    width: 1300px;
    margin: auto;
    padding-top:20px;
  }
  .searchBox{
    width:400px;
    padding-bottom:20px;
  }
  .row_item{
    height: 50px;
    line-height: 50px;
    border-bottom:1px solid #EDEFF6;
    padding-left: 15px;
  }
  .unnoitem{
    
  }
  .noData{
    height: 500px;
    line-height: 500px;
    text-align: center;
  }
</style>