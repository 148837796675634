<template>
  <div class="contenBox">
    <h1 style="margin-bottom:10px;font-size: 20px;color: #0c263f;">危化品名录</h1>
    <div class="search">
      <div class="searchFl">
        <el-input v-model="searchQuery.name" clearable size="medium" placeholder="请输入中文名/别名"></el-input>
        <el-input v-model="searchQuery.name_en" clearable size="medium" placeholder="请输入英文名"></el-input>
        <el-input v-model="searchQuery.cas_no" clearable size="medium" placeholder="请输入CAS NO."></el-input>
        <unnoSelect v-model="show_un_no" @unnoSelectFunc="unnoSelectFunc($event)" :unnoType="1" :width="'260px'"  :clearable="true"
          :placeTitle="'请输入UNNO'" :size="'medium'"></unnoSelect>
        <el-button type="primary" @click="searchChange" size="medium" icon="el-icon-search"
          style="background-color: #91be42;border-color: #91be42; margin-left: 10px;">搜索</el-button>
      </div>
    </div>
    <div class="unnoBox">
      <template v-if="total">
        <div>已查询到 {{ total }} 个结果</div>
      </template>
    </div>
    <div class="tableBlock">
      <el-table :data="tableData" style="width: 100%" :height="tableHeight" stripe :empty-text="emptyText"
        v-tableHeight="{ bottomOffset: 74 }" :tableLoading="loading">
        <el-table-column label="中文名" >
          <template slot-scope="scope">{{ scope.row.name_zh | textFormat }}</template>
        </el-table-column>
        <el-table-column label="英文名" >
          <template slot-scope="scope">{{ scope.row.name_en | textFormat }}</template>
        </el-table-column>
        <el-table-column label="别名" >
          <template slot-scope="scope">{{ scope.row.alias | textFormat }}</template>
        </el-table-column>
        <el-table-column label="CAS号"  width="100">
          <template slot-scope="scope">{{ scope.row.cas_no | textFormat }}</template>
        </el-table-column>
        <el-table-column label="化学品备注" >
          <template slot-scope="scope">{{ scope.row.remarks | textFormat }}</template>
        </el-table-column>
        <el-table-column label="UN编号" >
          <template slot-scope="scope">{{ scope.row.un_no | textFormat }}</template>
        </el-table-column>
        <el-table-column label="内河是否禁运"  width="120">
          <template slot-scope="scope">{{ scope.row.is_embargo == 1 ? 'Y' : 'N' }}</template>
        </el-table-column>
        <el-table-column label="禁运备注" >
          <template slot-scope="scope">
            <template v-if="scope.row.is_embargo == 1">
              {{ scope.row.embargo_remarks | limitedFormat }}
            </template>
            <template v-else></template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination @current-change="handleChange" :page-size="15" layout="total, prev, pager, next" :total="total" :current-page.sync="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import unnoSelect from "~ymp/components/common/unno"
import shipCompany from "~ymp/components/common/shipCompany"
export default {
  name: "hazardousDirectory",
  components: {
    unnoSelect,
    shipCompany
  },
  data() {
    return {
      loading: false,
      dialogShow: false,
      dialogShowUser: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      show_un_no:'',
      tableData: [],
      searchQuery: {
        un_no: "",
        name_zh: "",
        name_en: "",
        cas_no: ""
      },
      emptyText: '请先输入查询条件'
    };
  },
  mounted() {
  },
  methods: {
    unnoSelectFunc(e) {
      if (e) {
        this.searchQuery.un_no = e.un_no;
      } else {
        this.searchQuery.un_no = '';
      }
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        limit: 15,
        start: this.currentPage - 1,
        nopage: 0,
      };
      params = Object.assign(params, this.searchQuery)
      let result = await this.$store.dispatch( //
        "API_freight/hazardousDirectoryList",
        params
      );
      this.loading = false;
      if (result && result.success) {
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
        if(this.total == 0){
          this.emptyText = '该化学品不在危化品名录中'
        }
      }
    },
    searchChange() {
      this.$log({
        type: 1,
        topic: "ymp.tool",
        id: "ymp.tool.haz.search",
        content: JSON.stringify({
          unno: this.searchQuery.un_no || '',
          cas_no: this.searchQuery.cas_no || '',
          name_zh: this.searchQuery.name_zh || '',
          name_en: this.searchQuery.name_en || '',
        }),
      });
      this.tableData = [];
      this.currentPage = 1;
      this.getList();
    },
  },
  filters: {
    limitedFormat(val) {
      let str = "-";
      if (val == 1) {
        str = "全面禁运";
      }
      if (val == 2) {
        str = "禁止散装";
      }
      return str;
    },
  }
};
</script>
<style scoped lang="less">
.contenBox {
  width: 1300px;
  margin: auto;
  padding-top: 20px;
}

.search {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;
    .el-input{
      margin-right: 10px;
    }
  }
}

.unnoBox {
  display: flex;
  padding: 20px 10px;

  div {
    margin-right: 100px;
  }
}

.has-gutter {
  background: #EEEEEE;
}

.tips {
  color: #027fff;
  margin-left: 10px;
  cursor: pointer;
}

.paginationBlock {
  text-align: right;
  padding: 15px 0;
}
</style>